import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PlatformSection from "../components/PlatformSection";
import ManufacturingSolutions from "../components/ManufacturingSolutions";
import IntersectionBox from "../animations/IntersectionBox";
import RenderOnIntersectionBox from "../animations/RenderOnIntersectionBox";
import TrailingParagraph from "../animations/TrailingParagraph";
import RequestCallback from "../components/RequestCallback";
import GetQuoteSection from "../components/GetQuoteSection";
import MapSection from "../components/MapSection";
import Badge from "../components/Badge";
import { motion } from "framer-motion";

import { zoomInWithDelay, zoomOutWithDelay } from "../animations/variants";

function AboutUs() {
  const data = useStaticQuery(graphql`
    query {
      cl: strapi {
        index {
          client_logos {
            url
          }
        }
      }
      strapi {
        aboutUs {
          about_us_usps {
            logo_image {
              image {
                url
                previewUrl
              }
            }
            logo_title
          }
          partner_network_section {
            heading_text
          }
          second_section {
            description_text
            heading_text
          }
          title_description {
            heading_text
            description_text
          }
          partner_usps {
            logo_image {
              image {
                url
                alternativeText
              }
            }
            logo_title
          }
          seo {
            meta_keywords
            meta_description
            title
          }
        }
      }
    }
  `);

  const node = data.strapi.aboutUs;
  return (
    <Layout>
      <SEO
        title={node.seo.title}
        keywords={node.seo.meta_keywords}
        description={node.seo.meta_description}
      />
      <div className="content container-fluid">
        <div className="our-story">
          <div className="manuf-process">
            <p className="manuf-head">
              <TrailingParagraph
                paragraph={node.title_description.heading_text}
              />
            </p>
            <p className="manuf-desc">
              <TrailingParagraph
                paragraph={node.title_description.description_text}
              />
            </p>
            <IntersectionBox variants={zoomOutWithDelay(0.1)} threshold={0.1}>
              <div className="manuf-img">
                <video
                  muted
                  playsInline
                  autoPlay
                  loop="true"
                  id="platform-section-background-video"
                  className="about-us-background-video"
                  // poster={img}
                  preload="auto"
                  src={"/videos/about-custiv-banner.mp4"}
                ></video>
              </div>
            </IntersectionBox>
          </div>
          <div className="story-section container-fluid">
            <div className="row">
              <div className="story-info col-lg-6 col-md-6 col-sm-12">
                <h1 className="story-head">
                  <TrailingParagraph
                    paragraph={node.second_section.heading_text}
                  />
                </h1>
                <p className="story-desc">
                  <TrailingParagraph
                    paragraph={node.second_section.description_text}
                  />
                </p>

                <div className="client-logos about-us-client-logos">
                  <div className="inner">
                    {data.cl.index.client_logos.map((logo, index) => (
                      <IntersectionBox
                        threshold={0.9}
                        variants={zoomInWithDelay(0.2)}
                      >
                        <div className="client-logo">
                          <img src={logo.url} alt="Client Logos" />
                        </div>
                      </IntersectionBox>
                    ))}
                  </div>
                </div>
              </div>
              <div className="comp-icons col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div className="row">
                  {node.about_us_usps.map((usp, index) => (
                    <div className="icon-row col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <IntersectionBox variants={zoomInWithDelay(0.2)}>
                        {/* <div className="custiv-about-usp">
                          <div className="story-icon">
                            <figure>
                              <img src={usp.logo_image.image.url} />
                            </figure>
                          </div>
                          <p className="story-icon-desc"> {usp.logo_title} </p>
                        </div> */}
                        <Badge
                          className="about-us-usp"
                          text={usp.logo_title}
                          img={usp.logo_image.image.url}
                        />
                      </IntersectionBox>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <ManufacturingSolutions />
        <MapSection />
        <PlatformSection />

        <div className="partner-callback">
          <div className="join-network row">
            <div className="join-info col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <p className="join-head">
                Join our
                <br /> Partner Network
              </p>
              <p className="join-desc">
                <TrailingParagraph
                  paragraph="Add a new Digital sales by becoming our manufacturing partner.
                Sit back and relax while we review your application, we’ll get
                back to you as soon as possible."
                />
              </p>
              <IntersectionBox variants={zoomInWithDelay(0.5)}>
                <Link to="/partner-network">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="clippy_button learn-button"
                  >
                    Learn More
                  </motion.button>
                </Link>
              </IntersectionBox>
            </div>
            <div className="icon-info col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="row">
                {node.partner_usps.map((usp, index) => (
                  <div className="column col-lg-6 col-md-6 col-sm-6 col-xs-6">
                    <IntersectionBox>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div className="join-icon">
                          <figure>
                            <img
                              src={usp.logo_image.image.url}
                              alt={usp.logo_title}
                            />
                          </figure>
                        </div>
                        <p className="icon-desc"> {usp.logo_title} </p>
                      </div>
                    </IntersectionBox>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <RequestCallback />

        <GetQuoteSection />
      </div>
    </Layout>
  );
}

export default AboutUs;
