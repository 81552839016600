import React, { useState } from "react";
import { motion } from "framer-motion";
import { zoomIn } from "../animations/variants";
import TrailingParagraph from "../animations/TrailingParagraph";
import { navigate } from "gatsby";

const cardVariant = {
  hover: {
    scale: 1.05,
  },
};

const childVariant = {
  hover: {
    y: 0,
    opacity: 1,
  },
  hidden: {
    y: 30,
    opacity: 0,
  },
};

function Card({ title, description, img, href, selected }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <motion.div
      className={`expandable-card ${selected ? "selected" : ""}`}
      variants={cardVariant}
      whileHover="hover"
      onClick={() => {
        navigate(href);
      }}
    >
      <div className="card-main">
        <figure>
          <img src={img} alt={title} />
        </figure>
        <h2 className="card-head"> {title} </h2>
        <p className="card-desc">{description}</p>
        <motion.p className="learn-more" variants={childVariant}>
          Learn More
        </motion.p>
      </div>
    </motion.div>
  );
}

Card.defaultProps = {
  title: "Manufacturing Process Title",
  description: "Manufacturing Process Description",
  img: "",
  href: "/",
  selected: false,
};

export default Card;
