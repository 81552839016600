import axios from "axios";
import { motion } from "framer-motion";
import React from "react";
import { useForm } from "react-hook-form";
import IntersectionBox from "../animations/IntersectionBox";
import TrailingParagraph from "../animations/TrailingParagraph";
import { zoomInWithDelay } from "../animations/variants";

function RequestCallback({ title, description }) {
  const [submitted, setSubmitted] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const { register, handleSubmit, errors } = useForm();
  const [data, setData] = React.useState({});

  async function submit(data) {
    setSubmitting(true);
    try {
      const res = await axios.post(
        "https://api.custiv.com/api/forms/enquiry",
        data
      );
    } catch (error) {}
    setSubmitting(false);
    setData(data);
    setSubmitted(true);
  }

  return (
    <IntersectionBox threshold={0.6}>
      <div className="request-callback ">
        <div className="row">
          <div className="callback-info col-lg-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <h2 className="callback-head">
              <TrailingParagraph paragraph={title} />
            </h2>
            <p className="callback-desc">
              <TrailingParagraph paragraph={description} />
            </p>
          </div>
          <div className="form-section col-lg-6 col-md-6 col-sm-6 col-xs-12">
            {submitted && (
              <motion.div
                className="callback-form-submitted"
                initial="hidden"
                animate="show"
                variants={zoomInWithDelay(0.1)}
              >
                <TrailingParagraph
                  paragraph={`Thank you for contacting Custiv. We have received your request. Our team will call you shortly.`}
                />
              </motion.div>
            )}
            {!submitted && (
              <div className="callback-form">
                <form onSubmit={handleSubmit(submit)}>
                  <input
                    type="text"
                    name="name"
                    placeholder="Your Full Name"
                    id="fname"
                    required
                    ref={register({
                      required: {
                        value: true,
                        message: "Please input your name.",
                      },
                    })}
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Your Email ID"
                    id="email"
                    required
                    ref={register({
                      required: {
                        value: true,
                        message: "Please input your email.",
                      },
                    })}
                  />
                  <input
                    type="text"
                    name="phone"
                    placeholder="Your Number"
                    id="phnumber"
                    required
                    ref={register({
                      required: {
                        value: true,
                        message: "Please input your phone.",
                      },
                    })}
                  />
                  <button className="clippy_button button-4 request-callback-button">
                    {submitting ? (
                      <div className="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    ) : (
                      "Request Callback"
                    )}
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </IntersectionBox>
  );
}

RequestCallback.defaultProps = {
  title: "Request Callback from Custiv Team",
  description:
    "Leave us your details & learn how we can help you manufacture your product cheaper and faster",
};

export default RequestCallback;
