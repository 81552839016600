import { motion } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import IntersectionBox from "../animations/IntersectionBox";
import RenderOnIntersectionBox from "../animations/RenderOnIntersectionBox";
import TrailingParagraph from "../animations/TrailingParagraph";
import { zoomInWithDelay } from "../animations/variants";
import Card from "../components/card";

function getZoomIn(delay = 0) {
  return {
    show: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        delay: delay,
        type: "spring",
        bounce: 0.6,
        damping: 20,
        mass: 0.5,
        velocity: 1.7,
      },
    },
    hidden: {
      opacity: 0,
      scale: 0.8,
    },
  };
}

function ManufacturingSolutions() {
  const data = useStaticQuery(graphql`
    query {
      strapi {
        index {
          end_to_end {
            description_text
            heading_text
          }
          services_card {
            card_title
            href
            image {
              image {
                url
              }
            }
            visible_text
          }
        }
      }
    }
  `);

  const node = data.strapi.index;

  const [currentIndex, setCurrentIndex] = React.useState(0);

  function shiftCardToCenter(index) {
    const elem = document.querySelector(
      ".manufacturing-solutions .card-container"
    );
    const child = elem.children[index];
    const childCenter = child.offsetLeft + child.offsetWidth / 2;
    const containerCenter = elem.parentElement.parentElement.offsetWidth / 2;
    console.log("Shifting", childCenter, containerCenter);
    const shift = childCenter - containerCenter;
    const shiftPx = `${shift}px`;
    // if (shift > 0) {
    //   elem.style.marginLeft = -`${shift}px`;
    // }
    elem.scrollLeft = shift;
    setCurrentIndex(index);
  }

  function shiftCardContainer(index) {
    const elem = document.querySelector(
      ".manufacturing-solutions .card-container"
    );
    const cardWidth = elem.firstChild.offsetWidth;
    const shift = `-${index * cardWidth}px`;
    elem.style.marginLeft = shift;
    setCurrentIndex(index);
  }

  return (
    <div
      className="manufacturing-solutions triggerAnimation animated"
      data-animate="fadeInUp"
    >
      <div className="content">
        <h3 className="sol-head">
          <IntersectionBox>{node.end_to_end.heading_text} </IntersectionBox>
        </h3>

        <p className="sol-desc">
          <TrailingParagraph paragraph={node.end_to_end.description_text} />
        </p>
      </div>
      <IntersectionBox>
        <div className="tabs-container">
          <div className="tabs">
            {node.services_card.map((solution, index) => (
              <a
                href={`#tabs-${index}`}
                className={`${index === currentIndex ? "selected" : ""}`}
                onClick={() => {
                  shiftCardToCenter(index);
                }}
              >
                {solution.card_title}
              </a>
            ))}
          </div>
        </div>
      </IntersectionBox>
      <RenderOnIntersectionBox threshold={0.9}>
        <motion.div className="card-container">
          {node.services_card.map((service, index) => (
            <motion.div
              variants={zoomInWithDelay(0.2 + index * 0.2)}
              animate="show"
              initial="hidden"
            >
              <Card
                title={service.card_title}
                description={service.visible_text}
                href={service.href}
                img={service.image.image?.url}
                selected={currentIndex === index}
              />
            </motion.div>
          ))}
        </motion.div>
      </RenderOnIntersectionBox>
    </div>
  );
}

export default ManufacturingSolutions;
