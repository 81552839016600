import React, { useEffect, useRef } from "react";

function Badge({ img, text, img_width, width, className }) {
  const iconRef = useRef(null);

  useEffect(() => {
    const width = iconRef.current.offsetWidth;
    console.log(iconRef.current.offsetWidth);
    iconRef.current.style.height = `${width}px`;
  }, []);

  return (
    <div
      className={`badge-${className}`}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="badge-icon"
        ref={iconRef}
        style={{
          width: width,
          borderRadius: "50%",
          boxShadow: "0 0 10px 0 rgba(92, 93, 126, 0.2)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "10px",
          background: "white",
        }}
      >
        <div
          className="icon"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={img} style={{ width: img_width }} alt={text} />
        </div>
      </div>
      <div className="badge-text">{text}</div>
    </div>
  );
}

Badge.defaultProps = {
  img:
    "http://localhost:8000/static/8d170f2c0a89d2698142285984f9f928/7a7d5ba2026db197affd156937f11935.svg",
  text: "",
  img_width: "90%",
  width: "60%",
  className: "badge",
};

export default Badge;
